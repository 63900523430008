@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  overflow: hidden;
}

.App {
  background-image: url("./assets/bg.webp"); /* path to your image */
  background-size: cover;
  background-position: top left;
  height: 130vh;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: -5;
  align-items: center;
  justify-content: center;
  filter: grayscale(100%);
}

@media (max-width: 480px) {
  .App {
    background: black;
    height: 100vh;
    width: 100vw;
  }
  .lyric-content{
    overflow-y: scroll !important;
    WebkitOverflowScrolling: "touch";
  }
}
